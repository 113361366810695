.rc-pagination {
  @apply flex items-center min-h-[2rem];
}

.rc-pagination li {
  @apply border-y border-separation-900;
}
.rc-pagination-options,
.rc-pagination::after {
  @apply hidden;
}

.rc-pagination-item {
  transition: 0.2s linear;
  @apply min-w-[2rem] w-[2.375rem] min-h-[2rem] m-0 text-13 inline-flex items-center justify-center shrink-0 text-center rounded-none;
}

.rc-pagination-item:hover,
.rc-pagination-item-active {
  @apply !font-medium !border-primary-900 bg-primary-200;
}

.rc-pagination-item:hover a,
.rc-pagination-item-active a,
.rc-pagination-item-active a {
  @apply text-primary-900;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev {
  @apply m-0 shrink-0 min-h-[2rem] flex items-center justify-center w-[2.375rem];
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  @apply min-h-[2rem] inline-flex items-center justify-center m-0 rounded-none;
}

.rc-pagination-prev button:after,
.rc-pagination-next button:after {
  @apply hidden;
}
.rc-pagination-prev {
  @apply rounded-r-none rounded-l-lg border-l border-separation-900;
}

.rc-pagination-next {
  @apply rounded-l-none rounded-r-lg border-r border-separation-900;
}
.rc-pagination-prev:hover,
.rc-pagination-next:hover {
  @apply !font-medium !border-primary-900 bg-primary-200;
}
.rc-pagination-disabled:hover {
  @apply cursor-not-allowed !bg-white !border-separation-900;
}
