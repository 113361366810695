/* MOVEMENT TRACKING - HIDE SELETED OPTIONS */
.movement-tracking
  .select-selection-overflow
  > .select-selection-overflow-item:not(.select-selection-overflow-item-suffix) {
  @apply hidden !important;
}

.movement-tracking .select-item.select-item-option.select-item-option-disabled {
  @apply opacity-50 pointer-events-none;
}

/* FLEET TRACKING - HIDE SELETED OPTIONS */
.fleet-tracking
  .select-selection-overflow
  > .select-selection-overflow-item:not(.select-selection-overflow-item-suffix) {
  @apply hidden !important;
}

.fleet-tracking .select-item.select-item-option.select-item-option-disabled {
  @apply opacity-50 pointer-events-none;
}

/* PERFECT-SCROLLBAR */
.scrollbar-container.hide-x > .ps__rail-x {
  @apply hidden !important;
}

.scrollbar-container.hide-y > .ps__rail-y {
  @apply hidden !important;
}

/* PLAN SET - IMAGE UPLOADING MESSAGE */
.floor-plan-uploading-message {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), #000000;
  @apply border-0 text-white-800;
}

.floor-plan-uploading-message .title {
  @apply text-white-800 font-normal;
}

/* PLAN SET - MODAL EXPORT PLAN SET */
.export-planset-attn-field > div[id^='form-field'] {
  margin-bottom: 0 !important;
}

/* PREVEW PROJECT EXPORT ASSET */
#preview_print_export .pagedjs_pages {
  @apply flex flex-col gap-4;
}

#preview_print_export .pagedjs_pages .pagedjs_page {
  @apply shadow-card-2;
}

#preview_print_export .pagedjs_page .pagedjs_margin-bottom-right {
  @apply hidden w-full justify-end items-center text-sm;
}
#preview_print_export.show-page .pagedjs_page .pagedjs_margin-bottom-right {
  @apply flex;
}

/* REACT GRID LAYOUT */
.react-grid-item.react-grid-placeholder {
  @apply bg-transparent border-2 border-primary-900 rounded-lg opacity-100;
}
.react-grid-item.react-draggable-dragging.dropping {
  @apply bg-red-900;
}
.list-layout {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-auto-flow: dense;
}

.project-status .rc-virtual-list-holder-inner {
  height: 100px;
  overflow-y: scroll;
}
