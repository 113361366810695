@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./fontIcon.css');
@import url('./size.css');
@import url('./button.css');
@import url('./select.css');
@import url('./datePicker.css');
@import url('./toast.css');
@import url('./table.css');
@import url('./tabs.css');
@import url('./pagination.css');
@import url('./editor.css');
@import url('./gradient.css');
@import url('./other.css');

:root {
  --menu-gradient: linear-gradient(270deg, #1e2e46 0%, #121a26 100%);
  --button-single-hover: linear-gradient(0deg, #e5f2ff, #0054d1);
}
* {
  outline: none;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  @apply text-black-800 bg-separation-100;
}
.spinner {
  @apply relative;
}
.spinner div {
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ps__rail-y,
.ps__rail-y {
  @apply z-50;
}

ol,
ul,
menu {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
blockquote {
  @apply pl-2.5 ml-2.5 border-l-separation-900 border-l-[3px] my-2.5;
}

.bg-menu-gradient {
  background: linear-gradient(270deg, #1e2e46 0%, #121a26 100%);
}

#preview_print {
  @apply hidden;
}

#preview_print.show {
  @apply bg-white inline-block;
}
@media print {
  #root,
  #modal-root {
    @apply hidden;
  }
  #preview_print {
    @apply block overflow-hidden w-auto h-auto;
  }
  .print-hidden {
    @apply hidden;
  }
}
.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.line-clamp-1 {
  @apply line-clamp;
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  @apply line-clamp;
  -webkit-line-clamp: 2;
}
.line-clamp-4 {
  @apply line-clamp;
  -webkit-line-clamp: 4;
}
