.fi:before {
  display: none;
}

.slick-slide {
  z-index: 0;
}
.slick-slide.slick-active {
  z-index: 10;
}

.slick-track {
  margin: 0;
}
/* Recent Item Slide */
.slide-recent-item .slick-track {
  @apply !flex items-stretch;
}
.slide-recent-item .slick-slide {
  @apply h-auto;
}
.slide-recent-item .slick-slide > div {
  @apply h-full;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.custom-scrollbar {
  @apply cursor-auto scroll-smooth;
}
.custom-scrollbar::-webkit-scrollbar {
  @apply w-3 h-3 bg-transparent;
}
.custom-scrollbar::-webkit-scrollbar-button {
  @apply hidden w-0 h-0;
}
.custom-scrollbar::-webkit-scrollbar-corner {
  @apply bg-transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply border-[3px] border-solid border-transparent bg-clip-padding rounded-lg bg-separation-900;
}

.system-note strong {
  @apply font-semibold;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.apexcharts-xaxistooltip {
  @apply !bg-[#292929] !text-separation-100 !border-none !rounded-lg;
}
.apexcharts-xaxistooltip-bottom:after {
  @apply !border-b-[#292929];
}
.app__top-menu.open {
  @apply !z-[9999];
}

.react-pdf__Page__annotations {
  @apply !hidden;
}

.slide-swiping a {
  @apply pointer-events-none;
}

.calendar-iframe iframe {
  @apply w-full h-full;
}

@media print {
  .rc-table-optional-items-public tbody .rc-table-row,
  .rc-table-optional-items-public .rc-table-cell {
    @apply hidden;
  }
  .rc-table-optional-items-public tbody .rc-table-row.rc-table-row-selected {
    @apply table-row;
  }
  .rc-table-optional-items-public .rc-table-cell.thead-view-line-items {
    @apply table-cell;
  }
  .rc-table-optional-items-public colgroup col:first-child {
    @apply hidden;
  }
}
.table-invoice-line-item .rc-table-line-items tbody {
  @apply align-middle;
}

/* skeleton styles */
.move-left-to-right {
  animation: move 3s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(200%));
  }
}
