.mce-toolbar {
  @apply h-12;
}
.mce-content-body {
  @apply h-[calc(100vh_-_10.875rem)] border border-separation-800 shadow-card-2 overflow-auto p-10 text-13 leading-normal;
}
.mce-heading-custom,
.mce-content-body h1,
.mce-content-body h2,
.mce-content-body h3,
.mce-content-body h4,
.mce-content-body h5 {
  @apply my-3 font-bold leading-normal;
}
.mce-heading-custom.h1,
.mce-content-body h1 {
  @apply text-[2rem];
}
.mce-heading-custom.h2,
.mce-content-body h2 {
  @apply text-2xl;
}
.mce-heading-custom.h3,
.mce-content-body h3 {
  @apply text-xl;
}
.mce-heading-custom.h4,
.mce-heading-custom.h5,
.mce-content-body h4,
.mce-content-body h5 {
  @apply text-[1.15rem];
}
#table-list-line-item .rc-table-content {
  @apply !rounded-none;
}
#table-list-line-item table,
#table-list-line-item table th {
  @apply !border-none !text-[11px];
}
#table-list-line-item table th {
  @apply !bg-separation-200 pt-2 !rounded-none;
}
#table-list-line-item table td {
  @apply !border-l-0 !border-r-0 !border-t-0 border-b border-b-separation-400;
}
.editor-sale-proposal .mce-content-body {
  @apply py-20 mt-5;
}
.editor-sale-proposal .mce-content-body:before {
  @apply !left-10;
}
.editor-default .mce-content-body {
  @apply h-72 p-6 shadow-none bg-white;
}
.editor-default .mce-content-body:before {
  @apply !left-6;
}
.editor-default .mce-toolbar {
  @apply px-4 border border-b-0;
}

/****** MENTIONS ****/
.planSet--multiLine {
  @apply p-2;
}
.planSet__input {
  @apply w-full h-full text-white-600 text-xs !p-2 border-2 focus:border-primary-800 rounded-md leading-normal;
}
