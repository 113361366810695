.react-datepicker {
  font-family: inherit;
  background: transparent;
  color: inherit;
  border: none;
  border-radius: 0;
}

.react-datepicker__triangle {
  @apply hidden;
}

.react-datepicker__month-container {
  float: unset;
}

.react-datepicker__header {
  @apply border-0 p-0 bg-transparent;
}

.react-datepicker__day-names {
  @apply m-0 mb-2 mt-[1.125rem] flex gap-0;
}

.react-datepicker__day-name {
  @apply m-0 w-[2.5rem] text-[0.6875rem] font-normal text-black-400 leading-4 uppercase;
}

.react-datepicker__month {
  @apply m-0 border border-separation-400 rounded-lg;
}

.react-datepicker__week {
  @apply flex gap-0;
}
.react-datepicker.calendar-custom-timeline
  .react-datepicker__week
  .react-datepicker__day {
  @apply w-auto h-auto m-0 p-0;
}
.react-datepicker.calendar-custom-timeline
  .react-datepicker__week
  .react-datepicker__day
  .content-day {
  @apply w-10 h-10 flex items-center justify-center z-10;
}

.react-datepicker__week:not(:last-child) {
  @apply mb-0;
}

.react-datepicker__week:last-child .react-datepicker__day {
  @apply border-b-0;
}

.react-datepicker__day {
  @apply relative m-0 w-[2.5rem] h-[2.5rem] inline-flex justify-center items-center text-13 leading-normal text-black-800 select-none p-1;
  @apply border-r border-b border-r-separation-400 border-b-separation-400;
}
.react-datepicker__week-number {
  @apply relative m-0 w-[2.5rem] h-[2.5rem] inline-flex justify-center items-center text-13 leading-normal text-black-800 select-none p-1;
  @apply border-r border-r-separation-400;
}
.react-datepicker__day:last-child {
  @apply border-r-0;
}

.react-datepicker__day:hover {
  @apply font-medium !text-white relative z-10 bg-transparent rounded-none;
  @apply before:absolute before:inset-1 before:bg-primary-900 before:rounded-full before:z-[-1];
}

.react-datepicker__day--keyboard-selected {
  @apply bg-transparent;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selected,
.react-datepicker__week.hover .react-datepicker__day {
  @apply font-medium !text-white relative z-10 bg-transparent rounded-none;
  @apply before:absolute before:inset-1 before:bg-primary-900 before:rounded-full before:z-[-1];
}

.react-datepicker__day--disabled,
.react-datepicker__day--disabled:hover {
  @apply cursor-default !text-black-400 !bg-separation-800;
  @apply before:hidden;
}

.react-datepicker__day.react-datepicker__day--today {
  @apply text-primary-900;
}

.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day.react-datepicker__day--in-selecting-range {
  @apply bg-[#F5F6FF] text-primary-700;
}

.react-datepicker__day--outside-month {
  @apply text-black-300 bg-separation-100;
}

.double-range-picker .react-datepicker__day--outside-month {
  @apply cursor-default invisible opacity-0;
}

.react-datepicker__monthPicker {
  @apply mt-2 border-none;
}
.react-datepicker__monthPicker .react-datepicker__month-text {
  @apply h-10 w-16 align-middle leading-10 border border-separation-800 rounded-lg m-1;
}
.react-datepicker__monthPicker
  .react-datepicker__month-text.react-datepicker__month--selected {
  @apply bg-primary-800 font-medium;
}
.react-datepicker.react-datepicker--time-only {
  @apply !border border-separation-800 w-full border-solid;
}
.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
  @apply !bg-primary-900;
}
