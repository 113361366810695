.custom-tabs.rc-tabs {
  @apply w-full border-0;
}

.custom-tabs .rc-tabs-nav::after {
  content: '';
  @apply absolute inset-x-0 bottom-0 h-px bg-separation-800;
}

.custom-tabs .rc-tabs-nav {
  @apply px-4;
}

.custom-tabs .rc-tabs-nav-list {
  @apply w-full gap-1;
}

.custom-tabs .rc-tabs-nav-list .rc-tabs-tab {
  @apply flex-1;
}

.custom-tabs .rc-tabs-nav-list .rc-tabs-tab .rc-tabs-tab-btn {
  @apply w-full font-normal text-center text-13 min-h-[36px] leading-9;
}

.custom-tabs .rc-tabs-tab.rc-tabs-tab-active > .rc-tabs-tab-btn {
  @apply font-medium;
}

.custom-tabs .rc-tabs-nav-operations {
  @apply hidden pointer-events-none;
}

.custom-tabs .rc-tabs-ink-bar {
  @apply rounded-md bg-primary-700;
}

.custom-tabs .rc-tabs-content-holder {
  height: calc(100% - 36px);
}

.custom-tabs .rc-tabs-content {
  @apply h-full;
}
