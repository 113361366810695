.rc-table {
  @apply text-black-800 text-13;
}

.rc-table-content {
  @apply border border-separation-800 rounded-lg rounded-b-none;
}
.rc-table thead tr th {
  background: linear-gradient(0deg, #f9fafb, #f9fafb);
  @apply py-3 text-13 leading-normal capitalize;
}
.rc-table tr {
  @apply bg-transparent;
}
.rc-table tr th {
  @apply border-b-0 border-t-0 font-medium uppercase bg-transparent text-13 leading-normal;
}
.rc-table tr th:first-child {
  @apply rounded-tl-lg;
}
.rc-table tr th:last-child {
  @apply rounded-tr-lg;
}
.rc-table tr th:not(:last-child)::after {
  content: '';
  @apply absolute top-[50%] right-0 w-px h-[70%] bg-[#dedede] translate-y-[-50%];
}

.rc-table tr td.rc-table-cell-fix-left:not(:last-child)::after,
.rc-table tr td.rc-table-cell-right-left:not(:last-child)::after {
  content: '';
  @apply absolute top-[50%] right-0 w-px h-full bg-[#dedede] translate-y-[-50%];
}

.rc-table tr th.rc-table-cell-fix-left:not(:last-child)::after,
.rc-table tr th.rc-table-cell-fix-right:not(:last-child)::after {
  @apply h-full;
}

.rc-table th,
.rc-table td {
  transition: 0.2s linear;
  @apply border-r-0 border-t border-separation-800 py-4 px-4;
}

.rc-table-expanded-row-fixed::after {
  @apply border-0;
}
.rc-table-line-items .rc-table-content {
  @apply border-none;
}
.rc-table-line-items .rc-table-content tbody {
  @apply align-top;
}
.rc-table-line-items thead tr th {
  background: none;
  @apply pl-0 pr-3 bg-transparent border-none py-0 uppercase font-semibold;
}
.rc-table-line-items td {
  @apply border-none pl-0 pt-4 pb-0 pr-3;
}
.rc-table-line-items td:last-child {
  @apply pr-0 pt-0;
}

.rc-table-line-items tr th:not(:last-child)::after {
  display: none;
}

.rc-table-view-line-items td {
  @apply px-3 py-3 border-b border-b-separation-400 border-solid border-t-0;
}
.rc-table-view-line-items thead tr th {
  @apply px-3 capitalize font-medium pb-3 border-b border-b-separation-400 border-solid;
}

.rc-table-view-line-items td:last-child {
  @apply px-3 py-2;
}

.rc-table-cell.rc-table-cell-fix-left,
.rc-table-cell.rc-table-cell-fix-right {
  @apply bg-white;
}

.table-dashboard-on-project .rc-table-container {
  @apply border border-separation-800 rounded-lg rounded-b-none;
}
.table-dashboard-on-project .rc-table-container .rc-table-content {
  @apply !border-none;
}

.rc-table tr.rc-row-checked {
  @apply bg-primary-400;
}

.rc-table-fixed .rc-table td.td-column-fixed {
  @apply z-40;
}

.rc-table-fixed .rc-table tr td.rc-table-cell-fix-left:not(:last-child)::after,
.rc-table-fixed .rc-table tr th.rc-table-cell-fix-left:not(:last-child)::after {
  @apply z-popover -right-px;
}
.rc-table-fixed .rc-table tr th,
.rc-table-fixed .rc-table tr td {
  @apply border-r;
}
.rc-table-fixed .rc-table tr th.td-column-fixed-last,
.rc-table-fixed .rc-table tr td.td-column-fixed-last {
  @apply border-r-2;
}
