.bg-media-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 64%,
    rgba(0, 0, 0, 0.64) 100%
  );
}
.bg-avatar-gradient {
  background: linear-gradient(180deg, #f3f9ff 0%, #d2ecff 100%);
}
.bg-chart-tooltip {
  background: linear-gradient(180deg, #000000 0%, #ffffff 16%);
}
.bg-pagination-file {
  background: linear-gradient(0deg, #000000, #000000),
    linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
}
.bg-header-modal-profile {
  background: linear-gradient(270deg, #1e2e46 0%, #121a26 100%);
}

.bg-imageps-gradient {
  background: linear-gradient(
    180deg,
    rgba(3, 10, 27, 0) 39.58%,
    rgba(3, 10, 27, 0.8) 100%
  );
}
