.Toastify__toast-container {
  @apply w-fit;
}

.Toastify__toast-body {
  @apply p-0 m-0;
}

.custom-toast {
  @apply rounded-lg border border-separation-800 shadow-dropdown p-[0.625rem] max-w-[25rem] min-h-fit;
}
.custom-toast .toast-icon svg {
  @apply w-full h-full;
}
