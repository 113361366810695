/* --- PRIMARY --- */
.button-primary {
  @apply bg-button-primary text-white;
}
.button-primary:hover {
  @apply bg-button-primary-hover;
}
.button-primary:disabled {
  @apply bg-button-primary-disable;
}
.button-primary:disabled > .button-inner {
  cursor: not-allowed;
}

/* --- SECONDARY --- */
.button-secondary {
  @apply text-primary-900 border border-primary-900;
}
.button-secondary:disabled > .button-inner {
  opacity: 0.3;
  cursor: not-allowed;
}

/* --- TERNARY --- */
.button-ternary {
  @apply text-black-800 border border-none font-normal bg-button-ternary;
}
.button-ternary:hover {
  @apply bg-button-ternary-hover;
}
.button-ternary:disabled {
  @apply bg-button-ternary-disable opacity-50;
}
.button-ternary:focus {
  @apply bg-button-ternary-focus;
}
.button-ternary > .button-inner {
  @apply font-normal;
}
.button-ternary:disabled > .button-inner {
  cursor: not-allowed;
}

.button-single {
  @apply border-primary-900 text-primary-900 bg-transparent border;
}
/* 
.button-ternary:focus {
  @apply bg-separation-800;
} */
